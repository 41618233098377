import React, { useEffect } from 'react';
import SubComponent from './SubComponent';
import Aos from 'aos';

interface Props {
  component: any,
}

const LandingComponent: React.FC<Props> = ({component}) => {
  useEffect(()=> {
    Aos.init({
      duration: 1000,
      easing: 'ease',
      once: true
    })
  })

  return(
      <section id={component.id} className={"landing-component" + " " + component.target} data-aos="fade-up" >
        <div className="row">
          <div className="text-content" >
            <h2 className="h4">{component.sectionTitle}</h2>
            <h3 className='h2 title'>{component.title}</h3>
            <p className='text'>{component.text}</p>
          </div>
          <div className="image-content">
            <img src={component.heroImage} />
          </div>
        </div>
        <div className="sub-components-container">
          {component.subComponents.map((subComponent, index)=> (
            <SubComponent key={index} subComponent={subComponent} />
          ))}
        </div>
      </section>
  )
}

export default LandingComponent;
import React, { useState, useEffect } from 'react';
import Header from './components/Header';
import LandingPage from './components/LandingPage';
import Footer from './components/Footer';
import HeroSection from './components/landingComponents/HeroSection';
// import Subscribe from './components/landingComponents/Subscribe';
import Contact from './components/landingComponents/Contact';

import { GlobalProvider } from './context/GlobalButtonState';

export default function MainPage() {
  const [scroll, setScroll] = useState(false);
  window.addEventListener('scroll', (e)=> {
    e.preventDefault();
    e.stopPropagation();

    if (window.scrollY < 50) setScroll(false)
    else setScroll(true);
  })
  
  return(
    <GlobalProvider>
      <div className="App">
        <Header scroll={scroll} onPortal={false} sidebarVisibility={false} onChildClick={null}/>
        <HeroSection />
        <LandingPage />
        {/* <Subscribe /> */}
        <Contact />
        <Footer />
      </div>
    </GlobalProvider>
  )
}
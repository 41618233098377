import React from 'react';
import Header from './components/Header';
import ApiPortalNavigation from './components/apiDocsComponents/ApiPortalNavigation';
import Footer from './components/Footer';

export default function ApiDocsPortal() {
  window.scrollTo(0, 0);
  return(
    <>
    <Header scroll={true} onPortal={true} sidebarVisibility={false} onChildClick={null}/>
    <div className="api-docs-portal-container">
      <div className="container">
        <ApiPortalNavigation />
      </div>
    </div>
    <Footer />
    </>
  )
}
import img1 from '../assests/images/livedooh-dev-user-manual-2.svg';
import img2 from '../assests/images/livedooh-dev-REST-API-2.svg';
import img3 from '../assests/images/livedooh-dev-webhooks-2.svg';
import img4 from '../assests/images/livedooh-dev-Integrations-2.svg';

export const navLinks = [
    // {
    //   title: 'User\'s Manual',
    //   path: 'markdownDocs',
    //   target: '.target',
    // },
    {
      title: 'REST API',
      path: '/#rest-api',
      target: '.target2'
    },
    {
      title: 'Webhooks',
      path: '/#webhooks',
      target: '.target3'
    },
    {
      title: 'Integrations',
      path: '/#integrations',
      target: '.target4'
    },
]

let text = {
    "heroTitle": "The core of DOOH. Expandable. Accessible.",
    "heroSubtitle": "Start using a system capable to adapt to the real-time data input, capable of making predictions and automatically adjust.",

    "subscribe": {
      "title": "Never miss an update",
      "subtitle": "Subscribe and get the latest changes and updates to our platform.",
      "submit": "Get Updates"
    },

    "contact": {
      "title": "We’re here to help!"
    },

    "footer": {
    "title": "The most advanced platform for DOOH combining CMS, Ad Server and Playback.",
    "bottomFooter": {
      "copyright": "Livedooh GmbH © 2020",
      "impressum": "Impressum",
      "terms": "Terms",
      "privatePolicy": "Privacy Policy",
      "cookiesPolicy": "Cookies policy"
    }
  },

  "apiDocsPortal": {
    "apiDocs": {
      "title": "API",
      "text": "Campaign Media Plan Configurations"
    },
    "proofOfPlay": {
      "title": "Proof of Play",
      "text": "Proof of play API documentation"
    },
    "aggregatedReports": {
      "title": "Aggregated Reports",
      "text": "Get all Aggregation data for a campaign"
    },
    "learnMoreBtn": "Learn More"
  }
}

export default text;

export const content = [
  {        
    component: 'userManual',
    sectionTitle: 'User\' Manual',
    title: "Roll up your sleeves and get it started",
    text: "You’ve seen what our platform does and now you’re very eager to set things in motion. Hold your horses for a bit, and explore our user’s manual.",
    heroImage: `${img1}`,
    id: "users-manual",
    target: "target",

    subComponents: [
      {
        title: "Getting Started",
        text: "Step by step instructions for using LiveDooh platform.",
        authorizedBtnText: "Documentation",
        nonAuthorizedBtnText:  "Request Access",
        path: "/",
        animationDelay: 150
      },
      {
        title: "Installing the player",
        text: "The quick and hassle-free way of setting up the player.",
        authorizedBtnText: "Documentation",
        nonAuthorizedBtnText:  "Request Access",
        path: "/",
        animationDelay: 300
      },
      {
        title: "Samsung Tizen support",
        text: "Launching LiveDOOH app on Samsung Tizen.",
        authorizedBtnText: "Documentation",
        nonAuthorizedBtnText:  "Request Access",
        path: "/",
        animationDelay: 450
      }
    ]
  },

  {
    component: 'restApi',
    sectionTitle: 'REST API',
    title: "Complete set of tools for DOOH",
    text: "Shape you DOOH campaign at granular levels, combine it with your own tools and make the most out of it. Create a bespoke experience for viewers.",
    heroImage: `${img2}`,
    id: "rest-api",
    target: "target2",

    subComponents: [
      {
        title: "Campaign Management APIs",
        text: "Complete set of media planning from ads to audience targeting.",
        authorizedBtnText: "Documentation",
        nonAuthorizedBtnText:  "Request Access",
        path: "/",
        animationDelay: 150
      },
      {
        title: "Programmatic Buying and SSP Integration",
        text: "Customisable pricing and inventory solutions for you and your customers.",
        authorizedBtnText: "Documentation",
        nonAuthorizedBtnText:  "Request Access",
        path: "/",
        animationDelay: 300
      },
      {
        title: "CMS and Media Library API",
        text: "A user-friendly interface and media tagging and labelling APIs.",
        authorizedBtnText: "Documentation",
        nonAuthorizedBtnText:  "Request Access",
        path: "/",
        animationDelay: 450
      },
      {
        title: "Inventory Management APIs",
        text: "Fully-extensible data models that allow custom fields and schemas.",
        authorizedBtnText: "Documentation",
        nonAuthorizedBtnText:  "Request Access",
        path: "/",
        animationDelay: 600
      },
      {
        title: "Integration with other Players and CMS",
        text: "Native integration with Samsung Tizen screens and BroadSign.",
        authorizedBtnText: "Documentation",
        nonAuthorizedBtnText:  "Request Access",
        path: "/",
        animationDelay: 750
      },
    ]
  },
  {
    component: 'Webhooks',
    sectionTitle: 'Webhooks',
    title: "Get your events flowing",
    text: "Like in a domino game, you want things to go smoothly. To have that simple push and all the pieces to do the rest. To gain some time.",
    heroImage: `${img3}`,
    id: "webhooks",
    target: "target3",

    subComponents: [
      {
        title: "Getting Started",
        text: "Create webhooks for your day-to-day requirements.",
        authorizedBtnText: "Documentation",
        nonAuthorizedBtnText:  "Request Access",
        path: "/",
        animationDelay: 150
      },
      {
        title: "Webhook registration",
        text: "Connect the dots and make it flow.",
        authorizedBtnText: "Documentation",
        nonAuthorizedBtnText:  "Request Access",
        path: "/",
        animationDelay: 300
      },
      {
        title: "Samples",
        text: "See our list of example webhooks.",
        authorizedBtnText: "Documentation",
        nonAuthorizedBtnText:  "Request Access",
        path: "/",
        animationDelay: 450
      },
    ]
  },

  {
    component: 'integrations',
    sectionTitle: 'Integrations',
    title: "Play well with others",
    text: "All runs smooth when your tools just click. That’s the case with our third-party player integration with streaming API.",
    heroImage: `${img4}`,
    id: "integrations",
    target: "target4",

    subComponents: [
      {
        title: "3rd party player integration",
        text: "Seamlessly merge your custom player with LiveDOOH platform.",
        authorizedBtnText: "Documentation",
        nonAuthorizedBtnText:  "Request Access",
        path: "/",
        animationDelay: 150
      },
      {
        title: "Using the streaming API unit",
        text: "Enjoy into the event-driven potential of LiveDOOH API platform.",
        authorizedBtnText: "Documentation",
        nonAuthorizedBtnText:  "Request Access",
        path: "/",
        animationDelay: 500
      }
    ]
  },      
]

export const contactComponents = [
  {
    title: "Support",
    text: "Do not fear! Help is here! support@livedooh.com +49 721-7836-8759",
    target: "",
    path: ""
  },
  {
    title: "API Discussion Board",
    text: "Join our community of developers. Get help and advice from your peers",
    target: "",
    path: ""
  },
  {
    title: "Contact",
    text: "Don't be a stranger! info@livedooh.com +49 721-7836-8759",
    target: "",
    path: ""
  }
]

export const footer = [
  {
    listTitle: "Developers",
    buttons: [
      {
        title: "User's Manual",
        target: "",
        path: ""
      },
      {
        title: "REST API",
        target: "",
        path: ""
      },
      {
        title: "Webhooks",
        target: "",
        path: ""
      },
      {
        title:  "intergrations",
        target: "",
        path: ""
      }
    ]
  },
  {
    listTitle: "Livedooh",
    buttons: [
      {
        title: "Technology",
        target: "",
        path: "https://www.livedooh.com/technology/"
      },
      {
        title: "Media Owner",
        target: "",
        path: "https://www.livedooh.com/media-owner/"
      },
      {
        title: "Retail",
        target: "",
        path: "https://www.livedooh.com/retail/"
      },
      {
        title:  "Agencies",
        target: "",
        path: "https://www.livedooh.com/agencies/"
      }
    ]
  },
  {
    listTitle: "More",
    buttons: [
      {
        title: "Home",
        target: "",
        path: "https://www.livedooh.com/"
      },
      {
        title: "About",
        target: "",
        path: "https://www.livedooh.com/about-us/"
      },
      {
        title: "Contact",
        target: "",
        path: "https://www.livedooh.com/contact/"
      }
    ]
  }
]
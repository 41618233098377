import React, { useState } from 'react';
import { useAuth0 } from "../../react-auth0-spa";

interface Props {
  scroll?: boolean;
  onPortal: boolean;
}

export default function RequestAccessBtn<Props>({onPortal}) {
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();

  return(
    <div className={(onPortal) ? "request-access-btn hidden" : "request-access-btn"}>
      {!isAuthenticated && (
        <button className='btn btn-outline-white' onClick={() => loginWithRedirect({})}>&nbsp;&nbsp;&nbsp;Login</button>
      )}
      
      {isAuthenticated && <button className='btn btn-outline-white' onClick={() => logout()}>&nbsp;&nbsp;&nbsp;Log out</button>}
    </div>
  )
}
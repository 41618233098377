import React from 'react';
import heroImage from '../../assests/images/Feature-1.svg';
import text from '../../locale/LocaleEn';

export default function HeroSection () {
  return(
    <section className='hero-section'>
      <div className='container'>
        <img src={heroImage} />
        <h1>{text.heroTitle}</h1>
        <h2>{text.heroSubtitle}</h2>
      </div>
    </section>
  )
}
import React, { useState, useRef} from 'react';
import { Link } from 'react-router-dom';
import jump from '../../../node_modules/jump.js/dist/jump';
import mobileMenu from '../../assests/images/icons/menu-icon.png';

import { navLinks } from '../../locale/LocaleEn';

export default function Navigation({onPortal}) {
  const [jumpTimeout, setJumpTimout] = useState(0)
  const [navVisibility, setNavVisibility] = useState(false);

  function scrollTo(link) {
    if (onPortal) setJumpTimout(1000);
    setTimeout(()=> {
      jump(link.target, {
        duration: 1000,
        offset: -150,
        }, jumpTimeout);
    })
  }

  const navBar = useRef(null)

  function hideMobileSidebar() {
    console.log(navBar);
  }

  function toggleMobileSidebar() {
    if (!navVisibility) {
      setNavVisibility(true)
    } else {
      setNavVisibility(false)
      hideMobileSidebar();
    }
  }

  return(
    <nav className='navigation'>
      <ul ref={navBar} className={!navVisibility ? "hide" :  ""}> 
      <li>
        <a href="https://lvd-manual-temp-42.livedooh.com/" target="_blank">User's Manual</a>
      </li>
      { navLinks.map((link, index)=> (
        <li key={index} 
        onClick={()=> {
          scrollTo(link)
          setNavVisibility(false)
            }
          }>
          <Link to={link.path}>{link.title}</Link>
        </li>
      ))
      }
      </ul>
      <img className="mobile-menu-icon" src={mobileMenu} onClick={()=> ( toggleMobileSidebar())}/>
    </nav>
  )
}
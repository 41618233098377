import React, { useState } from 'react';
import Navigation from './headerComponents/Navigation';
import LogoContainer from './headerComponents/LogoContainer';
import RequestAccessBtn from './headerComponents/RequestAccessBtn';

interface Props {
  scroll: boolean;
  onPortal?: boolean;
  sidebarVisibility: boolean;
  onChildClick: any;
}

const Header: React.FC<Props> = ({scroll, onPortal}) => {

  return (
    <div className={!scroll ? 'header': 'header header--scrolled'}>
      <div className='container'>
        <div className='row'>
          <LogoContainer />
          <Navigation 
            onPortal={onPortal} 
          />
          <RequestAccessBtn onPortal={onPortal} />
        </div>
      </div>
    </div>
  )
}

export default Header;


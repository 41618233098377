import React from 'react';
import LandingComponent from './landingComponents/LandingComponent';
import { content, navLinks } from '../locale/LocaleEn';
import StickyNav from './landingComponents/StickyNav';

export default function LandingPage() {
  return(
    <section className='landing-page'>
      <div className="container">
        <ul className="sticky-nav-container">
          {
            // Creates the sticky navigation for landing page
          }
          <li>
            <a>User's Manual</a>
          </li>
          { navLinks.map((link, index)=> (
            <StickyNav key={index} link={link}/>
          ))}
        </ul>
        
        <div className="content">
          {
            // Creates the landing components with information
          }
          { content.map((component, index)=> (
            <LandingComponent key={index} component={component} />
          ))}
        </div>
       
      </div>
    </section>
  )
} 
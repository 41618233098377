import React from 'react';
import logo from '../../logo-dev.svg';
import { Link } from 'react-router-dom';

//TODO: Add link to home page

export default function LogoContainer() {
  return(
    <div className='logo-container'>
      <img src={logo} />
    </div>
  )
}

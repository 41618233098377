import React from 'react';
import text from '../locale/LocaleEn';
import FooterMenuList from '../components/FooterMenuList';

export default function Footer() {
  return(
    <section className='footer-section'>
    <div className="container">
      <div className="left-container">
        <p className="text">{text.footer.title}</p>
      </div>
      <div className="middle-container">
        <FooterMenuList />
      </div>
      <div className="right-container"></div>
    </div>
    <div className="bottom-container">
      <div className="copyright">{text.footer.bottomFooter.copyright}</div>
      <ul className="bottom-footer-middle-container">
        <li>{text.footer.bottomFooter.impressum}</li>
        <li>{text.footer.bottomFooter.terms}</li>
        <li>{text.footer.bottomFooter.privatePolicy}</li>
        <li>{text.footer.bottomFooter.cookiesPolicy}</li>
      </ul>
    </div>
    </section>
  )
}
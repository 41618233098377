import React from 'react';
import { footer } from '../locale/LocaleEn';

export default function FooterMenuList() {
  return(
    <ul>{footer.map((menu, i)=> (
        <li key={i} className="menu-list-container">
          <ul className="menu-list">
            <li className="title">{menu.listTitle}</li>
            {menu.buttons.map((item, i)=> (
              <li key={i}>
                <a href={item.path}></a>
                {item.title}
                </li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  )
}
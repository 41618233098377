import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';
import { useAuth0 } from "../../react-auth0-spa";

interface Props {
  subComponent: any,
}

const SubComponent: React.FC<Props> = ({ subComponent }) => {
  const { isAuthenticated } = useAuth0();

  return (
    <div className="sub-component">
      <h4 className="h4">{subComponent.title}</h4>
      <p className="">{subComponent.text}</p>
      <li className="link" >
        <Link to="/api-docs">
          {!isAuthenticated && (subComponent.nonAuthorizedBtnText)}
          {isAuthenticated && (subComponent.authorizedBtnText)}
        </Link>
      </li>
    </div>
  )
}

export default SubComponent;
import React, { createContext, useReducer } from 'react';
import AppReducer from './AppReducer';

const initialState = {
  buttons: [
    {
      id: 1, text: 'userManual', active: false
    },
    {
      id: 2, text: 'restApi', active: false
    },
    {
      id: 3, text: 'Webhooks', active: false
    },
    {
      id: 4, text: 'integrations', active: false
    }
  ]
}

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  return(<GlobalContext.Provider value={{
    buttons: state.buttons
  }}>
    {children}
  </GlobalContext.Provider>);
}
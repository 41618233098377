import React, {useEffect} from 'react';
import text from "../../locale/LocaleEn";
import heroImage from "../../assests/images/contact-square.svg";
import { contactComponents} from '../../locale/LocaleEn';
import Aos from 'aos';

export default function Contact() {
  useEffect(()=> {
    Aos.init({
      duration: 400,
      easing: 'ease',
      once: true
    })
  })
  return(
    <section className='contact-section'>
      <div className="container">
        <div className="row" data-aos="fade-up">
          <div className="image-container">
            <img src={heroImage} />  
          </div>
          <div className="text-container">
            <h2 className="title">{text.contact.title}</h2>
            <div className="sub-component-container">
              { contactComponents.map((comp, i)=> (
                <div key={i} className="sub-component">
                  <a href="https://www.livedooh.com/contact/">{comp.title}</a>
                  <p>{comp.text}</p>
                </div>
              ))}
            </div>
          </div>  
        </div>
      </div>
    </section>
  )
}